import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { ClickInfo } from 'bloko/blocks/drop';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { setScrollAfterLoginToStorage } from 'src/pages/Article/components/Rating/utils';

import VoteButton, { VoteButtonProps } from 'src/pages/Article/components/Rating/VoteButton';

const TrlKeys = {
    toDo: 'article.rating.clickInfo.toDo',
    login: 'article.rating.clickInfo.login',
};

export interface AnonymousVoteButtonProps extends VoteButtonProps {
    backUrlForLogin: string;
}

const AnonymousVoteButton: TranslatedComponent<AnonymousVoteButtonProps> = ({
    trls,
    backUrlForLogin,
    ...voteButtonProps
}) => {
    return (
        <ClickInfo
            render={(close) => (
                <>
                    <Text>{trls[TrlKeys.toDo]}</Text>
                    <VSpacing base={4} />
                    <Button
                        appearance={ButtonAppearance.Outlined}
                        kind={ButtonKind.Inversed}
                        Element={Link}
                        to={`/account/login?backUrl=${backUrlForLogin}`}
                        onClick={() => {
                            close && close();
                            setScrollAfterLoginToStorage();
                        }}
                    >
                        {trls[TrlKeys.login]}
                    </Button>
                </>
            )}
        >
            <VoteButton {...voteButtonProps} />
        </ClickInfo>
    );
};
export default translation(AnonymousVoteButton);
