import { FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import AreaSwitcherPromo from 'src/pages/Article/components/AreaSwitcherPromo';

const Landing: FC = () => {
    const showArticleAreaSwitcher = useSelector((state) => state.showArticleAreaSwitcher);

    return (
        <>
            <ContainerForXSL place="legacy-page-layout-xsl" />
            {showArticleAreaSwitcher && (
                <Portal place="promoAreaSwitcher">
                    <AreaSwitcherPromo />
                </Portal>
            )}
        </>
    );
};

export default Landing;
