import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { VacancySummaryType } from 'lux/models/vacancySummary/types';

import { getCompanyLink } from 'src/pages/Article/components/VacancySummary/utils';

import styles from './vacancy-summary.less';

type Props = {
    vacancy: VacancySummaryType;
};

const VacancySummaryCompanyLink: FC<Props> = ({ vacancy }) => {
    return (
        <span className={styles.company} data-qa="vacancy-summary-company">
            <BlokoLink Element={Link} to={getCompanyLink(vacancy.company)} kind={LinkKind.Secondary}>
                {`${vacancy.company.visibleName}, ${vacancy.area.name}`}
            </BlokoLink>
        </span>
    );
};

export default VacancySummaryCompanyLink;
