import { FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import ReactMigrationFromXSLWrapper from 'src/components/ReactMigrationFromXSLWrapper';

import Content from 'src/pages/Article/components/Content';

const Article: FC = () => {
    const pageMetaData = useSelector((state) => state.pageMetaData);
    const articlePage = useSelector((state) => state.articlePage);

    if (!articlePage) {
        return <ReactMigrationFromXSLWrapper />;
    }

    return (
        <PageLayout layout={EmptyLayout} {...pageMetaData}>
            <Content />
        </PageLayout>
    );
};

export default Article;
