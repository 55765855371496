import { VKShare } from 'src/app';

export interface ServicesData {
    getCounter: () => number;
    injectScript: (location: string, setCount: (count: number) => void) => void;
}

export const services: Record<string, ServicesData> = {
    vk: {
        injectScript: (location, setCount) => {
            const share = {
                counter: 0,
                count: (index: number, count: number) => {
                    if (window.VK) {
                        (window.VK.Share as VKShare).counter = count;
                    }
                },
            };

            if (window.VK) {
                window.VK.Share = share;
            } else {
                window.VK = {
                    Share: share,
                };
            }

            const script = document.createElement('script');
            script.setAttribute('src', `https://vk.com/share.php?act=count&index=1&url=${location}`);
            script.setAttribute('async', 'async');
            script.onload = () => {
                if (window.VK) {
                    setCount((window.VK.Share as VKShare).counter);
                }
            };
            document.body.appendChild(script);
        },
        getCounter() {
            return (window.VK?.Share as VKShare).counter || 0;
        },
    },
    ok: {
        injectScript: (location, setCount) => {
            window.ODKL = {
                counter: 0,
                updateCount: (uid, count) => {
                    if (window.ODKL) {
                        window.ODKL.counter = count;
                    }
                },
            };

            const script = document.createElement('script');
            script.setAttribute('src', `https://connect.ok.ru/dk?st.cmd=extLike&uid=odkl&ref=${location}`);
            script.setAttribute('async', 'async');
            script.onload = () => {
                if (window.ODKL) {
                    setCount(window.ODKL.counter);
                }
            };
            document.body.appendChild(script);
        },
        getCounter() {
            return window.ODKL?.counter || 0;
        },
    },
};
