import { FC, memo } from 'react';

import { ArticleBreadcrumb } from 'lux/models/articlePage';

import BreadcrumbsItem from 'src/pages/Article/components/Breadcrumbs/BreadcrumbsItem';

interface BreadcrumbsItemProps {
    breadcrumbsMarks: Array<ArticleBreadcrumb>;
}

const Breadcrumbs: FC<BreadcrumbsItemProps> = ({ breadcrumbsMarks }) => {
    const renderBreadcrumbs = breadcrumbsMarks.map((mark) => <BreadcrumbsItem key={mark} mark={mark} />);

    return <div className="breadcrumbs__wrapper">{renderBreadcrumbs}</div>;
};

export default memo(Breadcrumbs);
