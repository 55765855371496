import { FC, memo } from 'react';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { H2Promo } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { Image } from 'lux/models/articlePage';
import HumanDate from 'src/components/HumanDate';

interface HeaderInsiderProps {
    image: Image | undefined;
    publicationTime: string | undefined;
    title: string | undefined;
}

const HeaderInsider: FC<HeaderInsiderProps> = ({ image, publicationTime, title }) => {
    const getBackgroundImage = () => {
        if (!image) {
            return undefined;
        }
        return { backgroundImage: `url(${image.url})` };
    };

    return (
        <div className="landing-header" style={getBackgroundImage()}>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    <div className="landing-header-content">
                        {title && (
                            <H2Promo Element="h1" lite>
                                {title}
                            </H2Promo>
                        )}
                        {publicationTime && (
                            <>
                                <VSpacing base={7} xs={5} />
                                <div className="landing-header-content__info">
                                    <HumanDate date={publicationTime} />
                                </div>
                            </>
                        )}
                    </div>
                </Column>
            </ColumnsWrapper>
        </div>
    );
};

export default memo(HeaderInsider);
