import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'lux/modules/useSelector';

import styles from './employer-info.less';

const ArticleEmployerInfo: FC = () => {
    const info = useSelector((state) => state.articleEmployerInfo);

    if (!info) {
        return null;
    }

    return (
        <>
            {info.logo && <img loading="lazy" src={info.logo} className={styles.logo} />}
            <div className={styles.container}>
                <H2>{info.name}</H2>
                {info.siteUrl && (
                    <p>
                        <Link to={info.siteUrl}>{info.siteUrl}</Link>
                    </p>
                )}
                {!!info.industries.length && (
                    <>
                        <VSpacing base={3} />
                        {info.industries.map((industry) => (
                            <p key={industry}>{industry}</p>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

export default ArticleEmployerInfo;
