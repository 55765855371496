import { FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';

import ShareButton from 'src/pages/Article/components/ShareBar/ShareButton';

const ShareBar: FC = () => {
    const shareButtons = useSelector((state) => state.articlePage?.shareButtons);

    if (!shareButtons?.length) {
        return null;
    }

    const renderIcons = shareButtons.map((btn) => <ShareButton key={btn.type} {...btn} />);

    return <div className="cms-social-buttons">{renderIcons}</div>;
};

export default ShareBar;
